.card {
  position: relative;
  width: 206px;
  height: 117px;
  padding: 10px;
  background-color: var(--color-sonic-silver);
  border-radius: 8px;
}

.brand {
  position: relative;
  width: 36px;
  height: 21px;
  margin-top: 1px;
  background-color: var(--color-white);
  border-radius: 3px;
}

.brand .logo {
  position: absolute;
  top: 4px;
  left: 0;
  width: 26px;
  height: 12px;
  background-color: var(--color-light-gray);
  border-radius: 3px;
}

.number > h3 {
  margin: 0;
}

.number {
  display: flex;
  margin-top: 20px;
  color: var(--color-white);
}

.number .dots {
  display: flex;
  margin-left: 10px;
  align-items: flex-end;
}

.number .dot {
  width: 6px;
  height: 6px;
  margin-bottom: 2px;
  background-color: var(--color-white);
  border-radius: 50%;
}

.number .dot:not(:last-child) {
  margin-right: 2px;
}

.number .dot:nth-child(4n):not(:last-child) {
  margin-right: 8px;
}

.holderAndExpiration {
  display: flex;
  margin-top: 17px;
  font-family: var(--font-family-body);
  color: var(--color-white);
}

.holderAndExpiration .label {
  margin: 0 0 2px;
  font-size: 6px;
}

.holder {
  width: 100%;
  max-width: 155px;
}

.holder .label {
  opacity: 0;
}

.holder .name {
  margin: 0;
  overflow: hidden;
  font-size: 10px;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}

.expiration {
  text-align: center;
}

.expiration .date {
  margin: 0;
  font-family: var(--font-family-title);
  font-size: 10px;
  font-weight: bold;
}

.expiration .date span {
  margin: 0 1px;
  font-weight: 600;
}
