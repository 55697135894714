.payment {
  padding-bottom: 10px;
}

.header {
  margin: 0 20px;
}

.logo {
  display: flex;
  justify-content: center;
  width: 100%;
}

.logo img {
  width: 65px;
  padding: 16px 0 21px;
}

.container {
  margin: 0 20px 20px;
  background: var(--color-white);
}

.title {
  padding: 16px 18px;
  margin: 0;
  border-bottom: 1.5px solid var(--color-black);
}

.kit {
  padding: 18px;
  font-family: var(--font-family-title);
}

.kit h2 {
  margin: 0 0 10px;
}

.kit div {
  display: flex;
}

.kit p,
.subtotal p {
  margin: 5px 0;
  font-weight: 500;
}

.kit .price,
.subtotal .price {
  margin-left: auto;
  font-weight: 600;
}

.kit .item {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  border-bottom: solid 1px rgb(247, 247, 247);
}

.kit .item .name {
  padding-right: 10px;
}

.subtotal {
  display: flex;
  padding: 10px 20px;
  padding: 13px 18px;
  font-family: var(--font-family-title);
  border-top: 0.5px solid var(--color-silver);
  border-bottom: 0.5px solid var(--color-silver);
}

.prices {
  padding: 13px 18px;
  margin: 0;
}

.prices > h2 {
  margin: 5px 0 15px;
}

.prices h3 {
  margin: 0;
  font-weight: 500;
}

.price {
  display: flex;
  margin: 3px 0;
}

.price.total h2 {
  margin-bottom: 0;
}

.price h3 + h3,
.price h2 + h2 {
  margin-left: auto;
}

.free h3 + h3 {
  color: var(--color-ocean-green);
  font-weight: 600;
}

.green {
  color: var(--color-ocean-green);
}

.total {
  margin-top: 14px;
  border-top: 1px solid var(--color-black);
}

.paymentMethods {
  padding: 20px 20px 10px;
}

.paymentMethods > h2 {
  margin: 0 0 15px;
}

.paymentMethods p {
  font-family: var(--font-family-title);
  font-weight: 500;
}

.cardPreview {
  display: flex;
  margin: 32px 0 25px;
  justify-content: center;
}

.cardIcon {
  margin: 0px 5px;
}

.cardsContainer {
  display: flex;
  justify-content: center;
  margin: 10px 0px;
}

.label {
  padding: 17px 0 7px;
  margin: 0;
  font-weight: 600;
}

.shortInputs {
  display: flex;
  width: 100%;
}

.shortInputs > div {
  width: 50%;
}

.mediumInputs {
  display: block;
  width: 100%;
}

.expiration {
  margin-right: 10px;
}

.document {
  margin-right: 0;
}

.select {
  width: 100%;
  height: 30px;
  font-family: var(--font-family-title);
  font-size: var(--font-size-input);
  font-weight: 500;
  background-color: #fff;
  border: 1px solid var(--color-gray-web);
  outline: none;
}

/* Selector Hack for Safari only */
_::-webkit-full-page-media,
_:future,
:root .select {
  appearance: none;
  padding: 7px 12px;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.795 3.377a.232.232 0 0 1 .328.005l3.338 3.427c.09.092.236.094.328.005l3.428-3.338a.232.232 0 0 1 .328.005l.647.664a.232.232 0 0 1-.004.328L5.763 8.782a.232.232 0 0 1-.328-.005L1.126 4.352a.232.232 0 0 1 .004-.328l.665-.647Z' fill='%237F8386'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center, right bottom, right bottom, right bottom;
  background-size: 18px;
  border-radius: 0;
}

.select:hover {
  opacity: 0.93;
}

.button {
  width: 100%;
  margin: 20px 0 15px;
}

.leftBlock {
  width: 100%;
  margin-top: 16px;
}

.rightBlock {
  width: 100%;
}

.onlyDesktop {
  display: none;
}

.discount {
  background-color: var(--color-ocean-green);
  color: #fff;
  padding: 5px;
}

@media (max-width: 425px) {
  .discount {
    font-size: 8px;
    padding: 3px;
  }
}

@media (max-width: 350px) {
  .product {
    padding: 0 10px 20px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .onlyDesktop {
    display: inline;
  }

  .blocks {
    flex-direction: row-reverse;
  }

  .leftBlock {
    width: 69%;
    margin-top: 0;
  }

  .rightBlock {
    width: 29%;
    margin-left: 2%;
  }

  .kit .price,
  .subtotal .price {
    font-weight: 600;
  }

  .subtotal {
    padding: 13px 18px;
    margin: 0;
  }

  .prices {
    padding: 13px 18px;
    margin: 0;
  }

  .paymentMethods {
    padding: 20px 30px 10px;
  }

  .numberValidAndCvv {
    display: flex;
  }

  .numberValidAndCvv > div {
    width: 50%;
  }

  .numberValidAndCvv > div:first-child {
    padding-right: 5px;
  }

  .numberValidAndCvv > div:last-child {
    padding-left: 5px;
  }

  .button {
    width: 100%;
    max-width: 280px;
    margin: 20px auto 15px;
  }

  .mediumInputs {
    display: flex;
  }

  .mediumInputs > div {
    width: 50%;
  }

  .document {
    margin-right: 10px;
  }
}

/* Campaign order using hash modifications */
.campaign .prices,
.campaign .choices {
  display: none;
}

.campaign .button {
  margin-top: 0;
}
