.loading  {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo {
  width: 200px;
  height: auto;
  padding: 50px 0 150px;
  margin: 0 auto;
  align-self: center;
}
