.checkbox {
  display: flex;
  font-size: var(--font-size-h3);
  cursor: pointer;
  align-items: center;
}

.input input {
  position: absolute;
  width: 13px;
  height: 13px;
  cursor: pointer;
  opacity: 0;
}

.control {
  width: 11px;
  height: 11px;
  background: var(--color-cultured-darker);
  border: 1px solid var(--color-silver);
}

.input {
  display: flex;
}

.control svg {
  display: none;
  width: 100%;
  height: 100%;
}

.input input:checked + .control svg {
  display: block;
}

.input input:checked + .control {
  border-color: var(--color-ocean-green);
}

.label {
  width: 100%;
  margin-left: 10px;
  line-height: 1;
}
