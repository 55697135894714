.wrap {
  display: flex;
  width: 100%;
  padding: 20px;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.wrap h1,
.wrap p {
  width: 100%;
  text-align: center;
}

.block {
  width: 100%;
  margin: 0 auto;
}

.title {
  margin-bottom: 0;
}

.img {
  height: 92px;
  margin: 40px 0;
}

.btnBackToForm {
  width: 100%;
  margin-top: auto;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .wrap {
    min-height: unset;
    padding: 20px 20px 40px;
  }

  .wrap p {
    margin-bottom: 0;
    font-size: 16px;
  }

  .btnBackToForm {
    max-width: 279px;
    font-size: 18px;
  }
}
