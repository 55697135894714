.shippingInfo {
  display: flex;
}

.shippingData,
.buttonWrap,
.shipping {
  padding: 20px;
}

.label {
  padding-bottom: 7px;
  margin: 17px 0 0;
  font-weight: 600;
}

.firstLabel {
  margin-top: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.city {
  width: 50%;
}

.state h2 {
  padding-left: 0;
}

.number {
  width: 40%;
  max-width: 200px;
}

.number > div {
  margin-right: 10px;
}

.neighborhood {
  width: 100%;
}

.neighborhood h2 {
  padding-left: 0;
}

.shipping {
  width: 100%;
  border-top: 1px solid var(--color-silver);
  border-bottom: 1px solid var(--color-silver);
}

.shipping .title {
  display: flex;
  align-items: center;
}

.shipping .title svg {
  margin-right: 7px;
}

.shipping .title h4 {
  margin: 0;
}

.zipcode {
  margin-top: 7px;
  margin-bottom: 0;
}

.zipcode span {
  font-weight: 500;
}

.chosenMethod {
  display: flex;
  margin-top: 11px;
  justify-content: space-between;
}

.chosenMethod h2,
.chosenMethod h3 {
  margin: 0;
}

.price {
  color: var(--color-summer-green-1);
}

.chosenMethod p {
  margin: 3px 0;
  color: var(--color-quick-silver);
}

.dropdown {
  display: flex;
  margin-top: 16px;
  flex-direction: column;
}

.colorFreeShipment {
  color: var(--color-cadet-blue);
}

.button {
  width: 100%;
}

.grid {
  display: flex;
}

.w50 {
  width: 50%;
}

.grid .w50:first-child {
  padding-right: 5px;
}

.grid .w50:last-child {
  padding-left: 5px;
}

.leftBlock {
  width: 100%;
}

.rightBlock {
  width: 100%;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .leftBlock {
    width: 70%;
  }

  .rightBlock {
    width: 28%;
    margin-left: 2%;
  }

  .shipping {
    margin-top: 0;
    border-top: unset;
    border-bottom: unset;
  }

  .gridDesktop {
    display: flex;
  }

  .w50Desktop {
    width: 50%;
  }

  .w50Desktop:first-child,
  .w50:first-child {
    padding-right: 10px;
  }

  .w50Desktop:last-child,
  .grid .w50:last-child {
    padding-left: 10px;
  }

  .buttonWrap {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 4px 0 35px;
  }

  .button {
    width: 100%;
    max-width: 280px;
  }
}

.infoZipcode {
  color: var(--color-light-salmon);
  font-weight: normal;
  margin-left: 11px;
  cursor: pointer;
  border: 2px solid var(--color-light-salmon);
  padding: 1px;
}

.select {
  width: 100%;
  height: 27px;
  font-family: var(--font-family-title);
  font-size: var(--font-size-input);
  font-weight: 500;
  background-color: #fff;
  outline: none;
  padding: 5px 12px;
  border: 0px;
  outline: 0px;
}

.select::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.select::-webkit-scrollbar-thumb {
  background: var(--color-gray-web);
}

​.zipcodeNotFound {
  font-weight: bold;
  margin-bottom: 0px;
  color: var(--color-maximum-red);
}
