.content {
  display: flex;
  font-family: var(--font-family-title);
}

.icon {
  padding: 0 12px 0 0;
}

.content p {
  margin: 0;
  color: var(--color-black);
}
