.quantifier {
  display: flex;
  margin-left:auto;
  align-items: center;
}

.quantifier div {
  border: solid 0.5px black;
  padding: 6px 0;
  width: 27px;
  text-align: center;
  font-family: var(--font-family-title);
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  position: relative;
  cursor: default;
  user-select: none;
}

.quantifier .decrease,
.quantifier .increase {
  font-weight: bold;
  cursor: pointer;
}

.quantifier .decrease {
  border-right: none;
}

.quantifier .increase {
  border-left: none;
}

.quantifier .disabled {
    background-color: #d8d8d89e;
    color: #a0a0a0;
    border-color: #a0a0a0;
    pointer-events: none;
}
