.navigator {
  --box-size: 26px;
  --border-divider: 3;
}

.boxes {
  display: flex;
  width: 100%;
  height: var(--box-size);
  justify-content: space-between;
}

.step {
  display: flex;
  width: 100%;
  text-align: center;
  user-select: none;
  flex-wrap: wrap;
  justify-content: center;
}

.step:first-child {
  width: 90%;
}

.step:last-child {
  width: 110%;
}

.step .divider {
  height: 50%;
  flex-grow: 1;
  border-bottom: 0.5px solid var(--color-copper-crayola);
}

.step:first-child .divider:first-child,
.step:last-child .divider:last-child {
  border: unset;
}

.box {
  position: relative;
  display: flex;
  width: var(--box-size);
  height: 100%;
  justify-content: center;
  align-items: center;
}

.box:last-child {
  margin-right: 0;
}

.box::before {
  position: absolute;
  top: 0;
  left: 0;
  width: calc((var(--box-size) / var(--border-divider) - 1px));
  height: calc(var(--box-size) - 2px);
  content: "";
  border-top: 0.5px solid var(--color-black);
  border-bottom: 0.5px solid var(--color-black);
  border-left: 0.5px solid var(--color-black);
}

.box::after {
  position: absolute;
  top: 0;
  right: 0;
  width: calc((var(--box-size) / var(--border-divider) - 1px));
  height: calc(var(--box-size) - 2px);
  content: "";
  border-top: 0.5px solid var(--color-black);
  border-right: 0.5px solid var(--color-black);
  border-bottom: 0.5px solid var(--color-black);
}

.box .number {
  margin: 0;
  font-family: var(--font-family-title);
  font-size: 12px;
  font-weight: 600;
  color: var(--color-copper-crayola);
}

.titles {
  display: flex;
  width: 100%;
  user-select: none;
}

.title {
  width: 100%;
  margin: 9px 0 0;
  font-family: var(--font-family-title);
  font-size: 12px;
  font-weight: 500;
  color: var(--color-copper-crayola);
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.title:first-child {
  width: 90%;
}

.title:last-child {
  width: 110%;
}

.clickable {
  cursor: pointer;
}

/* Active */
.step .box::before {
  border-top: 0.5px solid var(--color-copper-crayola);
  border-bottom: 0.5px solid var(--color-copper-crayola);
  border-left: 0.5px solid var(--color-copper-crayola);
}

.step .box::after {
  border-top: 0.5px solid var(--color-copper-crayola);
  border-right: 0.5px solid var(--color-copper-crayola);
  border-bottom: 0.5px solid var(--color-copper-crayola);
}

.step.active .box .number {
  position: relative;
  color: var(--color-copper-crayola);
}

.title.active {
  font-weight: 600;
  color: var(--color-copper-crayola);
}

/* Previous boxes when active */
.step.active ~ .step .box .number {
  color: var(--color-sonic-silver);
}

.title.active ~ .title {
  color: var(--color-sonic-silver);
}

.step.active ~ .step .box::before {
  border-color: var(--color-sonic-silver);
}

.step.active ~ .step .box::after {
  border-color: var(--color-sonic-silver);
}

.step.active .divider:last-child,
.step.active ~ .step .divider {
  border-color: var(--color-sonic-silver);
}
