.toast {
  top: 5px;
  left: 10%;
  width: 80%;
}

@media (min-width: 480px) {
  .toast {
    top: 12px;
    left: 50%;
    width: 90%;
    max-width: 430px;
  }
}

.toast > div {
  border-radius: 0;
  box-shadow: 0 1px 10px 0 rgb(0, 0, 0, 0.18), 0 2px 15px 0 rgb(0, 0, 0, 0.1);
}

.toast > div > button {
  display: none;
}

.body {
  padding: 6px 12px !important;
}

.body > div > div {
  align-items: center;
}

.body > div p {
  padding-bottom: 2px;
  font-weight: 600;
}
