.logo {
  display: flex;
  justify-content: center;
  width: 100%;
}

.logo svg {
  width: 65px;
  padding: 16px 0 0;
}

.container {
  margin: 20px;
  background: var(--color-white);
}

.feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feedback h1 {
  margin: 30px 40px 0;
  text-align: center;
}

.feedback.confirmed h1 {
  color: var(--color-cadet-blue);
}

.feedback.processing h1 {
  color: var(--color-coral);
}

.feedback.denied h1,
.feedback.expired h1 {
  color: var(--color-french-raspberry);
}

.feedback svg {
  margin: 25px;
}

.orderInfo {
  padding: 0 25px;
  border-bottom: 1px solid var(--color-silver);
}

.orderInfo.confirmed h3 {
  color: var(--color-rich-black);
}

.orderInfo.confirmed p {
  color: var(--color-cadet-blue);
}

.orderInfo.processing h3 {
  color: var(--color-coral);
}

.orderInfo.processing p {
  color: var(--color-coral);
}

.clientInfo {
  padding: 15px 25px;
}

.clientInfo h1 {
  margin: 0 0 16px;
}

.clientInfo h3 {
  margin-top: 25px;
  font-family: var(--font-family-body);
}

.denied p {
  margin-top: 0;
  font-weight: bold;
  text-align: center;
}

.denied h2 {
  margin: 20px 25px 10px;
}

.denied ul {
  padding-right: 30px;
}

.denied li {
  margin-bottom: 14px;
}

.expired p {
  margin: 30px 50px 50px;
  font-weight: 500;
  text-align: center;
}

.button {
  width: 100%;
  padding: 0 25px 15px;
}

.button button {
  width: 100%;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .container {
    width: 75%;
    max-width: 713px;
    margin: 20px auto;
  }

  .button button {
    max-width: 280px;
    margin: 0 auto;
    font-size: 16px;
  }
}
