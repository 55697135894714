.buildkit {
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: 20px;
}

.formulaName {
  display: block;
  width: 100%;
  text-align: left;
  padding: 17px 21px 13px;
  border-bottom: solid 1px var(--color-silver);
}

.formulaName h4 {
  margin: 0;
}

.navbar {
  flex-shrink: 0;
  margin: 9px -20px 19px 0;
  overflow-x: hidden;
}

.logo {
  width: 65px;
  height: auto;
  padding-bottom: 18px;
  margin: 0 auto;
  align-self: center;
}

.loadingBuildkit {
  height: 100%;
  max-height: 510px;
  padding: 16px 20px;
}

.loadingHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.loadingHeader .loadingLogo {
  width: 136px;
  height: auto;
  align-self: center;
  margin: 70px auto;
}

.loadingContent {
  display: flex;
  justify-content: center;
  height: 100%;
}

.content {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  height: 100%;
  background-color: var(--color-white);
}

.header {
  width: 100%;
  padding: 15px 21px;
  border-bottom: 2px solid var(--color-black);
}

.chooseProductsTitle {
  margin: 0;
}

.products {
  display: block;
  width: 100%;
}

.chooseProductsTitle .onlyDesktop {
  display: none;
}

.product {
  padding: 0 15px 0 18px;
  border-bottom: solid 1px var(--color-silver);
}

.product .wrapper {
  display: flex;
  padding: 14px 0 8px;
}

.product .imageWrap {
  align-self: center;
  min-width: 55.5px;
}

.product .imageWrap img {
  max-width: 100%;
  max-height: 75px;
  margin: 0 8px;
  border-style: unset;
}

.product .details {
  display: flex;
  width: 100%;
  margin: 10px 0 10px 10px;
  flex-direction: column;
}

.product .details .name {
  width: 100%;
  margin: 0;
  font-size: var(--font-size-h2);
  font-weight: 600;
}

.product .details .qtdAndPrice {
  display: flex;
}

.product .description {
  margin-top: 3px;
  font-family: var(--font-family-title);
  font-size: 10px;
  font-weight: 500;
}

.product .price {
  margin: 0;
  font-weight: 500;
  font-size: var(--font-size-h2);
}

.product .quantifier {
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
  margin-left: auto;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.footer {
  width: 100%;
  padding: 0 18px 18px;
}

.btnAddToCart {
  width: 100%;
  margin-top: 22px;
}

@media (max-width: 340px) {
  .product .imageWrap {
    min-width: 45.5px;
  }
}

@media (max-width: 300px) {
  .product {
    padding-left: 7px;
  }

  .product .imageWrap {
    min-width: 25.5px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .buildkit {
    padding: 28px 43px;
  }

  .logo {
    width: 134px;
  }

  .header {
    border-bottom: unset;
  }

  .chooseProductsTitle {
    padding: 10px 0;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
  }

  .chooseProductsTitle .onlyDesktop {
    display: inline-block;
    margin-right: 5px;
  }

  .content {
    justify-content: center;
    padding: 32px;
  }

  .formulaName {
    display: none;
  }

  .products {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .product {
    flex: 1 0;
    width: 25%;
    min-width: 271px;
    padding-right: 19.5px;
    padding-left: 19.5px;
    margin: 0;
    margin-top: 37px;
    border: unset;
  }

  .product .wrapper {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    padding: 17px 22px 36px;
    border: solid 1px var(--color-black);
  }

  .product .imageWrap {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .product .imageWrap img {
    max-height: 163px;
  }

  .product .description {
    margin-bottom: 10px;
  }

  .product .details {
    margin: 27px 0 0;
  }

  .product .details .name,
  .product .details .price {
    font-size: 20px;
  }

  .product .details .description {
    margin-top: 4px;
    font-size: 16px;
  }

  .product .quantifier {
    align-items: flex-end;
  }

  .footer {
    display: flex;
    justify-content: center;
  }

  .btnAddToCart {
    width: auto;
    padding: 10px 30px !important;
    margin: 60px 0 20px;
    font-size: 18px;
  }
}
