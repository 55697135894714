.container {
  position: relative;
  display: inline-flex;
  width: 100%;
  border: 1px solid var(--color-gray-web);
}

.container.valid {
  border: 1px solid var(--color-ocean-green);
}

.container.valid .prepend {
  color: var(--color-ocean-green);
  border-right: 1px solid var(--color-ocean-green);
}

.valid .input {
  color: var(--color-ocean-green);
}

.validationIcon {
  position: absolute;
  top: calc(50% - 7px);
  right: 8px;
  width: 14px;
  height: auto;
}

.container.invalid {
  border: 1px solid var(--color-maximum-red);
  box-shadow: 0 4px 4px rgba(var(--color-new-york-pink-rgb), 0.8);
}

.container.invalid .prepend {
  border-right: 1px solid var(--color-maximum-red);
}

.invalid .input {
  padding: 3px 25px 5px 12px;
}

.input.invalid[type='date'] {
  padding: 4.5px 25px 4.5px 12px;
}

.message {
  margin: 6px 7px;
  font-family: var(--font-family-body);
  font-size: 10px;
  font-style: normal;
  font-weight: normal;
}

.message.invalid {
  color: var(--color-maximum-red);
}

.message.valid {
  color: var(--color-ocean-green);
}

.input:disabled,
.input.disabled,
.input:disabled::placeholder,
.input.disabled::placeholder {
  color: var(--color-silver);
}

.input:disabled,
.input.disabled {
  background-color: var(--color-cultured);
}
