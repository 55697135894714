.dropdown {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.title {
  font-family: var(--font-family-title);
  font-weight: 600;
  color: var(--color-international-orange-aerospace);
  user-select: none;
}

.content {
  display: none;
}

.content.active {
  display: block;
  height: auto;
  transition: max-height 0.2s ease-out;
}

.icon {
  width: 7px;
  height: auto;
  padding: 4px;
  fill: var(--color-international-orange-aerospace);
  transform: rotate(180deg);
}

.icon.active {
  transform: rotate(0deg);
}

.dropdown.disabled > .title {
  color: var(--color-light-salmon-lighter);
}

.dropdown.disabled > .icon {
  fill: var(--color-light-salmon-lighter);
}
