.cart {
  display: flex;
}

h4 {
  font-family: var(--font-family-title);
  font-size: var(--font-size-h2);
}

.description {
  font-size: 11px;
  font-weight: 500;
  color: var(--color-dim-gray);
}

.description b {
  font-weight: 500;
  color: var(--color-black);
}

.productsSummary {
  width: 100%;
}

.rightBlocks {
  width: 100%;
}

.formulas {
  padding-top: 15px;
  padding-bottom: 15px;
}

.formulas .formula + .formula {
  padding-top: 15px;
  border-top: 1.5px solid var(--color-black);
}

.formulas .formula + .formula .border {
  padding-top: 15px;
}

.formula .header,
.benefit .header {
  display: inline-flex;
  width: 100%;
  padding: 0 15px 14px;
  border-bottom: 1px solid var(--color-silver);
}

.border {
  border-top: 1px solid var(--color-silver);
  padding-top: 0;
}

.formula .header .info {
  margin: auto 0;
}

.formula h2,
.formula h3 {
  margin: 0;
}

.formula .actions {
  z-index: 1;
  display: flex;
  margin-left: auto;
}

.formula .actions div {
  display: flex;
  width: 27px;
  height: 26px;
  margin-left: 9px;
  cursor: pointer;
  border: 0.5px solid var(--color-black);
  align-items: center;
  justify-content: center;
}

.formula .pensilIcon {
  width: 14px;
  height: auto;
}

.formula .trashIcon {
  width: 14px;
  height: auto;
}

.formula .products {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.benefit h2 {
  margin: 5px 0;
}

.product {
  position: relative;
  width: 100%;
  padding: 0 15px 0 18px;
  border-bottom: solid 1px var(--color-silver);
}

.product:last-child {
  border-bottom: 0;
}

.product .wrapper {
  display: flex;
  padding: 14px 0;
}

.product .imageWrap {
  align-self: center;
}

.product .benefit {
  margin: auto 0 auto auto;
  font-size: 14px;
}

@media (min-width: 321px) {
  .product .imageWrap {
    min-width: 55.5px;
    display: flex;
  }
}

.product .imageWrap img {
  max-width: 100%;
  max-height: 75px;
  margin: 0 auto;
  border-style: 0;
}

.product .details {
  display: flex;
  justify-content: center;
  padding: 10px 5px 10px 10px;
  flex-direction: column;
}

.product .details .name {
  width: 100%;
  margin: 0;
  font-weight: 600;
  font-size: var(--font-size-h2);
}

.product .description {
  margin-top: 3px;
  font-family: var(--font-family-title);
  font-size: 10px;
  font-weight: 500;
}

.product .price {
  margin: 0;
  font-size: var(--font-size-h2);
  font-weight: 500;
}

.subtotal {
  display: flex;
  justify-content: space-between;
  padding: 18px;
  margin-top: 10px;
  border-top: 1px solid var(--color-silver);
  border-bottom: 1px solid var(--color-sonic-silver);
}

.subtotal p {
  margin: 0;
  font-family: var(--font-family-title);
  font-size: var(--font-size-h3);
}

.addMoreFormulas .wrap {
  padding: 18px;
  border-bottom: 1px solid var(--color-sonic-silver);
}

.addMoreFormulas h4 {
  margin-top: 0;
  text-align: center;
  letter-spacing: -0.3px;
}

.addMoreFormulas .content {
  display: flex;
  align-items: center;
  padding: 0 2px;
}

.addMoreFormulas .content .bottlePlus {
  width: 50px;
  vertical-align: middle;
  flex-shrink: 0;
}

.addMoreFormulas .callToAction {
  padding-left: 14px;
}

.addMoreFormulas .callToAction p {
  margin-top: 0;
  line-height: 1.3;
  color: var(--color-davys-grey);
}

.addMoreFormulas .btn {
  font-size: var(--font-size-body);
}

.shipping {
  padding: 19px 18px;
  border-bottom: 1px solid var(--color-silver);
}

.shipping .title {
  display: flex;
  align-items: center;
}

.title h4 {
  display: flex;
  align-items: center;
}

.shipping h4 {
  margin: 0 0 0 10px;
}

.shipping .truckIcon {
  width: 20px;
}

.shipping .change {
  display: flex;
  justify-content: space-between;
}

.shipping .calculate {
  display: flex;
  flex-wrap: wrap;
  padding-top: 7px;
}

.shipping .cep,
.cupom .input {
  width: 53%;
  padding: 0 5px;
}

.btnWrap {
  width: 47%;
  padding-left: 7px;
}

.shipping .btn,
.cupom .btn {
  width: 100%;
  padding: 7px;
  font-family: var(--font-family-title);
  font-size: var(--font-size-body);
  font-weight: 600;
}

.cupom {
  padding: 18px;
  font-size: var(--font-size-h2);
  border-bottom: 1px solid var(--color-silver);
}

.cupom .validate {
  display: flex;
  padding-top: 7px;
}

.cupom .input input {
  text-transform: uppercase;
}

.cupom .input input::placeholder {
  text-transform: none;
}

.summary {
  margin: 13px 18px 0;
  border-bottom: 1px solid var(--color-black);
}

.summary div {
  display: flex;
}

.summary h4 {
  margin: 4px 0;
  font-size: var(--font-size-h3);
  font-weight: 600;
}

.total {
  display: flex;
  padding: 18px 18px 0;
}

.total.activeSummary {
  padding-top: 8px;
}

.total h4 {
  margin: 0;
  font-family: var(--font-family-title);
  font-size: var(--font-size-h1);
  font-weight: bold;
}

.summary .price,
.total .price {
  margin-left: auto;
  text-align: right;
}

.total .price span {
  font-family: var(--font-family-body);
  font-size: var(--font-size-body);
  font-weight: 500;
  color: var(--color-quick-silver);
}

.summary .price.free {
  color: var(--color-cadet-blue);
}

.btnFinishWrap {
  padding: 18px;
}

.btnFinish {
  width: 100%;
}

@media (max-width: 340px) {
  .product .imageWrap {
    min-width: 38px;
  }

  .addMoreFormulas .content .bottlePlus {
    width: 35px;
  }
}

@media (max-width: 300px) {
  .product {
    padding-left: 7px;
  }

  .product .imageWrap {
    min-width: 25.5px;
  }

  .addMoreFormulas .content .bottlePlus {
    display: none;
  }
}

@media (max-width: 1199px) {
  .border {
    border-top: none;
  }
  .formulas .formula + .formula .border {
    padding-top: 0px;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .description {
    display: none;
  }

  .productsSummary {
    width: 69%;
  }

  .formulas {
    padding: 24px 30px 0;
  }

  .border {
    padding-top: 0;
    border-top: 1px solid var(--color-silver);
  }

  .formulas .formula + .formula,
  .benefit {
    padding-top: 0;
    border-top: none;
  }

  .formula .header,
  .benefit .header {
    position: relative;
    padding: 21px 21px 16px;
    border: solid 1px var(--color-silver);
  }

  .formula h2 {
    font-size: 16px;
  }

  .formula .products,
  .benefit .products {
    padding: 22px;
    border-right: solid 1px #b4b4b4;
    border-left: solid 1px #b4b4b4;
  }

  .product {
    width: 45%;
    min-width: 280px;
    padding: 0;
    border-bottom: 0;
  }

  .product .details .name {
    font-size: 16px;
  }

  .rightBlocks {
    width: 29%;
    margin-left: 2%;
  }

  .addMoreFormulas .wrap {
    padding: 20px 24px;
    border-bottom: 0;
  }

  .addMoreFormulas .btnAddFormula {
    width: 100%;
    font-weight: 600;
    white-space: normal;
  }

  .addMoreFormulas h4 {
    font-size: var(--font-size-h2);
  }

  .addMoreFormulas .callToAction {
    width: calc(100% - 50px);
    font-size: var(--font-size-h3);
  }

  .shipping {
    margin-top: 16px;
  }

  .shipping h4 {
    font-size: 16px;
  }

  .subtotalWrap {
    padding: 0 30px 30px;
  }

  .subtotal {
    position: relative;
    display: flex;
    margin: 0;
    border: 0;
    border: solid 1px #b4b4b4;
    justify-content: flex-end;
  }

  .subtotal p:first-child {
    margin-right: 6px;
  }

  .total h4 {
    font-size: 18px;
    font-weight: 700;
  }

  .btnFinish {
    font-size: 16px;
  }
}

/* Campaign order using hash modifications */
.cart.campaign .formula .actions,
.cart.campaign .formula .product .price,
.cart.campaign .subtotal,
.cart.campaign .addMoreFormulas,
.cart.campaign .cupom,
.cart.campaign .total {
  display: none;
}

.cart.campaign .products {
  border-bottom: 1px solid var(--color-sonic-silver);
}

@media (min-width: 1200px) {
  .cart.campaign .products {
    border-bottom: 1px solid solid 1px #b4b4b4;
  }

  .cart.campaign .shipping {
    margin-top: 0;
  }
}

.infoZipcode {
  color: var(--color-light-salmon);
  font-weight: normal;
  margin-left: 11px;
  cursor: pointer;
  border: 2px solid var(--color-light-salmon);
  padding: 1px;
}
