.block {
  width: 100%;
}

.personalData {
  padding: 20px;
}

.label {
  padding-bottom: 7px;
  margin: 17px 0 0;
  font-weight: 600;
}

.personalData > .label:first-child {
  margin-top: 0;
}

.shortInput {
  width: 175px;
}

.phone {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

.checkbox {
  margin: 10px 0 17px;
}

.checkbox span {
  font-size: var(--font-size-checkbox);
}

.button {
  width: 100%;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .block {
    width: 70%;
    margin: 0 auto;
  }

  .personalData {
    padding: 32px;
  }

  .desktop2Columns {
    display: flex;
    flex-wrap: wrap;
  }

  .desktop2Columns > div {
    width: 50%;
  }

  .desktop2Columns > div:first-child {
    padding-right: 10px;
  }

  .desktop2Columns > div:last-child {
    padding-left: 10px;
  }

  .shortInput {
    width: 100%;
  }
}
