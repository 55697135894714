@keyframes loading {
  0%,
  40%,
  100% {
    transform: scale(0.4);
  }

  20% {
    transform: scale(0.7);
  }
}

.loadingDots > ul {
  display: flex;
  justify-content: center;
  padding: 0;
}

.loadingDots > ul > li {
  list-style: none;
  background: var(--color-dark-salmon);
  animation: loading 1.6s infinite;
}

.loadingDots > ul > li:nth-child(1) {
  animation-delay: -1.4s;
}

.loadingDots > ul > li:nth-child(2) {
  animation-delay: -1.2s;
}

.loadingDots > ul > li:nth-child(3) {
  animation-delay: -1s;
}

.loadingDots > ul > li:nth-child(4) {
  animation-delay: -0.7s;
}
