:global(#root) {
  height: 100%;
}

.paymentProcessing {
  height: 100%;
  max-height: 510px;
  padding: 16px 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header .logo {
  width: 136px;
  height: auto;
  align-self: center;
  margin: 70px auto;
}

.content {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 15px 0 20px;
  font-family: var(--font-family-title);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content h1,
.content p {
  width: 100%;
  text-align: center;
}

.content p {
  width: 225px;
  font-size: var(--font-size-h2);
  font-weight: 500;
}
