.radio {
  display: flex;
  width: 19px;
  height: 19px;
  box-sizing: content-box;
  cursor: pointer;
  background-color: var(--color-platinum);
  border: 1px solid var(--color-silver);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.radio > .innerCircle {
  width: 0;
  height: 0;
  border-radius: 50%;
  transition: width 0.1s linear, height 0.1s linear;
}

.radio.selected > .innerCircle {
  width: 9px;
  height: 9px;
  background-color: var(--color-copper-crayola);
}

.radio.disabled {
  background-color: var(--color-platinum);
  border: 1px solid var(--color-light-gray);
}

.radio.selected.disabled > .innerCircle {
  background-color: var(--color-champagne-pink);
}
