.block {
  width: 100%;
}

.content {
  display: flex;
  padding: 20px 20px 10px;
  font-family: var(--font-family-title);
  flex-direction: column;
  align-items: center;
}

.boxOrQrCode {
  display: flex;
  width: 100%;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.box {
  margin: 5px 0;
  fill: var(--color-light-salmon);
}

.qrCode {
  display: none;
  width: 160px;
  padding: 10px;
  margin-top: 10px;
}

.steps {
  margin: 0 5px;
}

.steps div {
  display: flex;
}

.steps h2,
.steps p {
  margin: 15px 0;
  line-height: 14px;
}

.steps p {
  margin-left: 6px;
  font-weight: normal;
}

.dropdown {
  display: flex;
  padding: 5px 25px 15px;
  flex-direction: column;
}

.dropdown h2 {
  font-weight: 600;
  color: var(--color-international-orange-aerospace);
}

.pixCode {
  max-width: 500px;
  margin-top: 14px;
  word-break: break-all;
  border: 1px solid var(--color-black);
}

.pixCode p {
  padding: 10px;
  font-family: var(--font-family-title);
  font-weight: 600;
}

.buttons {
  width: 100%;
  padding: 7px 15px;
}

.buttons .button {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 7px;
  font-size: var(--font-size-h1);
}

.button.copied {
  background-color: var(--color-copper-crayola);
}

.firstCopy {
  display: none;
}

.steps .mobileOnly {
  display: block;
}

.steps .desktopOnly {
  display: none;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .block {
    width: 77%;
    margin: 0 auto;
  }

  .content {
    align-items: flex-start;
    padding: 20px 30px 10px;
  }

  .firstCopy,
  .countDown,
  .valueToPay {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
  }

  .countDown {
    margin: 6px 0;
  }

  .firstCopy {
    display: inline;
    margin-bottom: 0;
  }

  .valueToPay {
    margin-top: 7px;
  }

  .box {
    display: none;
  }

  .qrCode {
    display: block;
  }

  .steps {
    width: 100%;
    margin: 20px 0 0;
  }

  .steps .mobileOnly {
    display: none;
  }

  .steps .desktopOnly {
    display: block;
  }

  .steps h2 {
    font-size: 16px;
  }

  .steps p {
    font-size: 14px;
  }

  .dropdown {
    padding: 5px 40px 20px;
    font-size: 14px;
  }

  .dropdown h2 {
    font-weight: 600;
    color: var(--color-international-orange-aerospace);
  }

  .buttons {
    max-width: 390px;
    margin-right: auto;
    margin-left: auto;
  }

  .pixCode {
    max-width: none;
  }
}
