.checkoutContainer {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  padding: 16px 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header .iconGoBack {
  position: absolute;
  width: 12px;
  height: auto;
  cursor: pointer;
}

.header .iconGoBack svg {
  align-self: center;
}

.header .logo {
  width: 65px;
  height: auto;
  align-self: center;
  margin: 0 auto;
}

.progressIndicator {
  width: 100%;
  max-width: 375px;
  margin: 22px 0 17px;
}

.noProgressIndicator {
  width: 100%;
  margin: 8px 0;
}

.blocksWrap {
  justify-content: center;
  display: flex;
  width: 100%;
}

.blocks {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  justify-content: left;
}

.footer {
  position: relative;
  bottom: -40px;
  z-index: -1;
  padding: 17px;
}

.footer p {
  width: 100%;
  margin: 0;
  color: var(--color-dim-gray);
  text-align: center;
}

.footer .slogan {
  font-family: var(--font-family-title);
  font-weight: bold;
}

.footer .website {
  margin-top: 4px;
  font-family: var(--font-family-body);
  font-size: 11px;
}

.secure {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .header .logo {
    width: 96px;
  }

  .checkoutContainer {
    height: 100%;
    padding: 25px 20px 0;
  }

  .progressIndicator {
    margin: 27px 0;
  }

  .noProgressIndicator {
    margin: 15px 0;
  }

  .blocks {
    width: 78%;
  }

  .footer {
    display: block;
  }
}
